import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'

const Garden = ({ data }) => {
  const garden = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <GatsbySeo title="Home Page" />

      <GridContent id="introduction" mode="white" layout="--2-column">
        <div className="text-content-margin">
          <h2>{garden.introduction.title}</h2>
          <h3>{garden.introduction.sub_heading}</h3>
          <p>{garden.introduction.body}</p>
        </div>

        <BgImage
          image={getImage(garden.introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent
        id="garden-page-1"
        layout="--2-column"
        mode="paradise-garden-club"
      >
        <BgImage
          className="image-background float-left"
          image={getImage(garden.page_1.image)}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
          }}
        />
        <h3 className="markdown content-margin-bottom content-margin-top quote">
          {garden.page_1.body}
        </h3>
      </GridContent>

      <GridContent
        id="garden-page-2"
        mode="white-mode"
        layout="--2-column content-margin-bottom content-margin-top"
      >
        <GatsbyImage image={getImage(garden.page_2.image)} alt="page 2 image" />
        <p className="text-content-margin">{garden.page_2.body}</p>
      </GridContent>
      <GatsbyImage
        id="full-bleed-image"
        image={getImage(garden.full_image)}
        alt="background"
        style={{ maxHeight: '60vh', width: '100%' }}
      />
      <GridContent
        id="garden-page-3"
        mode="white-mode"
        layout="--2-column content-margin-top content-margin-bottom"
      >
        <p className="text-content-margin">{garden.page_3.body}</p>

        <GatsbyImage
          image={getImage(garden.page_3.image_right)}
          alt="background"
        />
      </GridContent>
      <GridContent
        id="garden-page-4"
        mode="white-mode"
        layout="--2-column content-margin-bottom"
      >
        <BgImage
          className="image-background"
          image={getImage(garden.page_4.image_left)}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
          }}
        />
        <div className="space-evenly">
          <p className="markdown">{garden.page_4.body}</p>
          <Link
            className="align-right mock-button"
            to={garden.page_4.button.link}
          >
            {garden.page_4.button.label}
          </Link>
        </div>
      </GridContent>

      <MaxiMenu />
    </Layout>
  )
}

export default Garden

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "garden" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                sub_heading
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }

              full_image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              page_1 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_2 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_3 {
                body
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_4 {
                body
                button {
                  label
                  link
                }
                image_left {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
